import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent } from '@/lib/vue';

interface IProps {
  previousTooltip: string;
  nextTooltip: string;
}

interface IEvents {
  onToday: () => void;
  onPrevious: () => void;
  onNext: () => void;
}

export const DateNavigationBtns = createComponent<IProps, IEvents>({
  name: 'DateNavigationBtns',
  props: {
    previousTooltip: { type: String, required: true },
    nextTooltip: { type: String, required: true },
  },
  setup(props, ctx) {
    function emitToday() {
      ctx.emit('today');
    }

    function emitPrevious() {
      ctx.emit('previous');
    }

    function emitNext() {
      ctx.emit('next');
    }

    return () => (
      <div id="DateNavigationBtns" class="flex flex-row flex-initial">
        <v-btn outlined onClick={emitToday} class="mr-2">
          Hoje
        </v-btn>

        <v-btn
          icon
          aria-label={props.previousTooltip}
          data-balloon-pos="down"
          onClick={emitPrevious}
        >
          <v-icon>{MyIcons.chevronLeft}</v-icon>
        </v-btn>

        <v-btn
          icon
          aria-label={props.nextTooltip}
          data-balloon-pos="down"
          onClick={emitNext}
        >
          <v-icon>{MyIcons.chevronRight}</v-icon>
        </v-btn>
      </div>
    );
  },
});
