import { FormFields } from '@/components/form/fields/FormFields';
import { ExpandTransition } from '@/components/utils/ExpandTransition';
import { useState } from '@/lib/composables';
import { ConstantsHelper } from '@/lib/constants/helper';
import { LookupsConfigs } from '@/lib/form/lookups';
import { createComponent } from '@/lib/vue';
import { FinancasRelatoriosState } from '@/store/modules/financas/financasRelatorios.store';
import {
  IFinancasRelatoriosWhere,
  IFormInputLayout,
  IFormSchema,
} from '@/typings';
import { computed } from '@vue/composition-api';

interface IProps {
  show: boolean;
}

interface IEvents {}

export const FinancasRelatoriosFilters = createComponent<IProps, IEvents>({
  name: 'FinancasRelatoriosFilters',
  props: {
    show: { type: Boolean, default: true },
  },
  setup(props, ctx) {
    const $schema = useSchema();

    const $where = useState(s => s.financas.relatorios.where);

    function handleWhere(w: IFinancasRelatoriosWhere) {
      FinancasRelatoriosState.setWhere(w);
    }

    return () => (
      <div id="FinancasRelatoriosFilters" class="-mx-4 bg-coolGray-100">
        <ExpandTransition hide={!props.show}>
          <div class="px-4 pt-4 pb-2">
            <FormFields
              full
              schema={$schema.value}
              value={$where.value}
              onInput={handleWhere}
            />
          </div>
        </ExpandTransition>
      </div>
    );
  },
});

function useSchema() {
  const $where = useState(s => s.financas.relatorios.where);

  const layout = (opts: IFormInputLayout = {}): IFormInputLayout => ({
    sm: 4,
    marginBottom: 4,
    ...opts,
  });

  return computed<IFormSchema<IFinancasRelatoriosWhere>>(() => ({
    categoriaId: {
      label: 'Categoria',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.categoriaFinanceira(),
      hideDetails: true,
      layout: layout(),
    },
    centroCustoId: {
      label: 'Centro de custo',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.centroCusto(),
      hideDetails: true,
      layout: layout(),
    },
    contaFinanceiraId: {
      label: 'Conta',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.contaFinanceira(),
      hideDetails: true,
      layout: layout(),
    },
    convenioId: {
      label: 'Convênio',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.convenio(),
      hideDetails: true,
      layout: layout(),
    },
    pacienteId: {
      label: 'Paciente',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.paciente(),
      hideDetails: true,
      layout: layout(),
    },
    pago: {
      label: 'Status',
      type: 'select',
      items: ConstantsHelper.transacaoStatus,
      clearable: true,
      hideDetails: true,
      layout: layout({ minWidth: 180, sm: 2 }),
    },
    profissionalId: {
      label: 'Profissional',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.user.profissionais({
        onModelChange() {
          FinancasRelatoriosState.setWhere({
            ...$where.value,
            procedimentoId: null,
          });
        },
      }),
      hideDetails: true,
      layout: layout(),
    },
    procedimentoId: {
      label: 'Procedimento',
      type: 'autocomplete',
      itemLabel: 'nome',
      lookup: LookupsConfigs.procedimento({
        profissionalId: $where.value.profissionalId,
        noCache: true,
      }),
      disabled: !$where.value.profissionalId,
      hideDetails: true,
      layout: layout(),
    },
  }));
}
