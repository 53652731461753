import { PieChart } from '@/components/charts/PieChart';
import { useState } from '@/lib/composables';
import { ChartHelpers } from '@/lib/helpers/chart.helpers';
import { createComponent } from '@/lib/vue';
import { IPieChartData } from '@/typings';

interface IProps {}

interface IEvents {}

export const FinancasRelatoriosChart = createComponent<IProps, IEvents>({
  name: 'FinancasRelatoriosChart',
  setup(props, ctx) {
    const $data = useState<IPieChartData[]>(s =>
      s.financas.relatorios.data
        .filter(f => !f.isTotal)
        .map(v => ({
          label: v.profissional
            ? `${v.profissional} - ${v.label}`
            : v.label || '',
          value: v.value,
        })),
    );

    return () => (
      <div id="FinancasRelatoriosChart" class="flex justify-center">
        <PieChart
          type="doughnut"
          value={$data.value}
          colors={ChartHelpers.pie.colors()}
          tooltipFormat={{ money: true }}
        />
      </div>
    );
  },
});
