import { DataTable } from '@/components/datatable/DataTable';
import { CircularLoading } from '@/components/loading/CircularLoading';
import { useState } from '@/lib/composables';
import { formatDecimal } from '@/lib/form';
import { assertUnreachable } from '@/lib/helpers/utils';
import { createComponent } from '@/lib/vue';
import { IDataTableHeader, IFluxoCaixaDataValue } from '@/typings';
import { computed } from '@vue/composition-api';

interface IProps {}

interface IEvents {}

export const FluxoCaixaTable = createComponent<IProps, IEvents>({
  name: 'FluxoCaixaTable',
  setup(props, ctx) {
    const $loading = useState(
      s =>
        s.financas.fluxoCaixa.report.loading ||
        s.financas.fluxoCaixa.categorias.loading ||
        s.financas.fluxoCaixa.contasFinanceiras.loading,
    );

    const $headers = useHeaders();

    const $items = useItems($headers);

    return () => (
      <div id="FluxoCaixaTable" class="flex flex-col px-4">
        <CircularLoading loading={$loading.value}>
          <DataTable
            headers={$headers.value}
            items={$items.value}
            noSearch
            showAll
            outlined
            noHover
            // height="calc(100vh - 280px)"
          />
        </CircularLoading>
      </div>
    );
  },
});

function useHeaders() {
  const $state = useState(s => s.financas.fluxoCaixa);

  function mapClass({ type }: IFluxoCaixaItem) {
    return {
      // layout
      'grid items-center h-12 px-4 -mx-4': true,

      // receitas
      'bg-green-100 bg-opacity-60': type === FluxoCaixaRowType.RECEITA_TOTAL,
      'bg-green-100 bg-opacity-25': [
        FluxoCaixaRowType.RECEITA_CATEGORIA,
        FluxoCaixaRowType.RECEITA_OUTROS,
      ].includes(type),

      // despesas
      'bg-red-100 bg-opacity-60': type === FluxoCaixaRowType.DESPESA_TOTAL,
      'bg-red-100 bg-opacity-25': [
        FluxoCaixaRowType.DESPESA_CATEGORIA,
        FluxoCaixaRowType.DESPESA_OUTROS,
      ].includes(type),

      // transferencias, geracaoCaixa, saldoAnterior
      'bg-coolGray-50': [
        FluxoCaixaRowType.TRANSFERENCIA_TOTAL,
        FluxoCaixaRowType.GERACAO_CAIXA,
        FluxoCaixaRowType.SALDO_ANTERIOR,
      ].includes(type),

      // saldoFinal
      'font-bold bg-coolGray-100': type === FluxoCaixaRowType.SALDO_FINAL,
    };
  }

  return computed<IDataTableHeader<IFluxoCaixaItem>[]>(() => {
    const { data } = $state.value.report;

    return [
      {
        text: '',
        value: 'label',
        sortable: false,
        divider: true,
        mapClass,
      },
      ...Object.keys(data).map<IDataTableHeader<IFluxoCaixaItem>>(
        (date, i) => ({
          text: date,
          value: date,
          mapValue: v => formatDecimal(v[date]),
          mapClass,
          sortable: false,
          align: 'center',
          valueAlign: 'center',
          divider: i < 6,
        }),
      ),
    ];
  });
}

function useItems($headers: ReturnType<typeof useHeaders>) {
  const $state = useState(s => s.financas.fluxoCaixa);

  const $rows = computed<IFluxoCaixaRow[]>(() => {
    const { receitas, despesas } = $state.value.categorias;

    return [
      {
        type: FluxoCaixaRowType.RECEITA_TOTAL,
        label: 'RECEITAS',
      },
      ...receitas.map(v => ({
        id: v.id,
        label: v.nome,
        type: FluxoCaixaRowType.RECEITA_CATEGORIA,
      })),
      {
        type: FluxoCaixaRowType.RECEITA_OUTROS,
        label: 'Outros',
      },

      // DESPESAS
      {
        type: FluxoCaixaRowType.DESPESA_TOTAL,
        label: 'DESPESAS',
      },
      ...despesas.map(v => ({
        id: v.id,
        label: v.nome,
        type: FluxoCaixaRowType.DESPESA_CATEGORIA,
      })),
      {
        type: FluxoCaixaRowType.DESPESA_OUTROS,
        label: 'Outros',
      },

      // TRANSFERẼNCIAS
      {
        type: FluxoCaixaRowType.TRANSFERENCIA_TOTAL,
        label: 'TRANSFERÊNCIAS',
      },
      {
        type: FluxoCaixaRowType.TRANSFERENCIA_ENTRADA,
        label: 'Entrada',
      },
      {
        type: FluxoCaixaRowType.TRANSFERENCIA_SAIDA,
        label: 'Saída',
      },

      // TOTALS
      {
        type: FluxoCaixaRowType.GERACAO_CAIXA,
        label: 'Geração de caixa',
      },
      {
        type: FluxoCaixaRowType.SALDO_ANTERIOR,
        label: 'Saldo anterior',
      },
      {
        type: FluxoCaixaRowType.SALDO_FINAL,
        label: 'SALDO FINAL',
      },
    ];
  });

  return computed(() =>
    $rows.value.map(row => {
      const { data } = $state.value.report;

      return {
        label: row.label,
        type: row.type,
        ...$headers.value.slice(1).reduce(
          (obj, h) => ({
            ...obj,
            [h.value]: mapDataValue({ value: data[h.value], row }),
          }),
          {},
        ),
      };
    }),
  );
}

function mapDataValue({
  value,
  row,
}: {
  value: IFluxoCaixaDataValue | undefined | null;
  row: IFluxoCaixaRow;
}): number {
  if (!value) return 0;

  switch (row.type) {
    case FluxoCaixaRowType.RECEITA_TOTAL:
      return value.receitas.total;
    case FluxoCaixaRowType.RECEITA_CATEGORIA:
      return value.receitas.categorias[row.id!] || 0;
    case FluxoCaixaRowType.RECEITA_OUTROS:
      return value.receitas.outros;

    case FluxoCaixaRowType.DESPESA_TOTAL:
      return value.despesas.total;
    case FluxoCaixaRowType.DESPESA_CATEGORIA:
      return value.despesas.categorias[row.id!] || 0;
    case FluxoCaixaRowType.DESPESA_OUTROS:
      return value.despesas.outros;

    case FluxoCaixaRowType.TRANSFERENCIA_TOTAL:
      return value.transferencias.total;
    case FluxoCaixaRowType.TRANSFERENCIA_ENTRADA:
      return value.transferencias.entrada;
    case FluxoCaixaRowType.TRANSFERENCIA_SAIDA:
      return value.transferencias.saida;

    case FluxoCaixaRowType.GERACAO_CAIXA:
      return value.geracaoCaixa;

    case FluxoCaixaRowType.SALDO_ANTERIOR:
      return value.saldoAnterior;

    case FluxoCaixaRowType.SALDO_FINAL:
      return value.saldoFinal;

    default:
      return assertUnreachable(row.type);
  }
}

interface IFluxoCaixaItem {
  type: FluxoCaixaRowType;
  label: string;
}

interface IFluxoCaixaRow {
  id?: string;
  type: FluxoCaixaRowType;
  label: string;
}

enum FluxoCaixaRowType {
  RECEITA_TOTAL = 'RECEITA_TOTAL',
  RECEITA_CATEGORIA = 'RECEITA_CATEGORIA',
  RECEITA_OUTROS = 'RECEITA_OUTROS',
  DESPESA_TOTAL = 'DESPESA_TOTAL',
  DESPESA_CATEGORIA = 'DESPESA_CATEGORIA',
  DESPESA_OUTROS = 'DESPESA_OUTROS',
  TRANSFERENCIA_TOTAL = 'TRANSFERENCIA_TOTAL',
  TRANSFERENCIA_ENTRADA = 'TRANSFERENCIA_ENTRADA',
  TRANSFERENCIA_SAIDA = 'TRANSFERENCIA_SAIDA',
  GERACAO_CAIXA = 'GERACAO_CAIXA',
  SALDO_ANTERIOR = 'SALDO_ANTERIOR',
  SALDO_FINAL = 'SALDO_FINAL',
}
