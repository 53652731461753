import { FormFields } from '@/components/form/fields/FormFields';
import { useState, useValue } from '@/lib/composables';
import { ConstantsHelper } from '@/lib/constants/helper';
import { dateRangePresetsDashboard } from '@/lib/constants/dateRangePresets';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { FinancasRelatoriosService } from '@/lib/services/financas/financasRelatorios.service';
import { createComponent } from '@/lib/vue';
import { IFinancasRelatoriosState, IFormSchema } from '@/typings';
import { computed } from '@vue/composition-api';
import { FinancasRelatoriosFilters } from './FinancasRelatoriosFilters';

interface IProps {}

interface IEvents {}

export const FinancasRelatoriosHeader = createComponent<IProps, IEvents>({
  name: 'FinancasRelatorioHeader',
  setup(props, ctx) {
    const { $model, $schema } = useHeaderData();

    const { $filter, $filterIcon, $filterTooltip, handleFilter } = useFilter();

    return () => (
      <div id="FinancasRelatorioHeader" class="flex flex-col space-y-4">
        <div class="flex items-center space-x-4">
          <FormFields
            class="mb-2"
            schema={$schema.value}
            value={$model.value}
            onInput={FinancasRelatoriosService.setHeader}
          />

          <v-btn
            color="secondary"
            outlined
            class="ml-auto"
            aria-label={$filterTooltip.value}
            data-balloon-pos="down"
            onClick={handleFilter}
          >
            <v-icon left>{$filterIcon.value}</v-icon>
            Filtros
          </v-btn>

          <v-btn color="primary" onClick={FinancasRelatoriosService.loadData}>
            <v-icon left>{MyIcons.fileChart}</v-icon>
            Gerar relatório
          </v-btn>
        </div>

        <FinancasRelatoriosFilters show={$filter.value} />
      </div>
    );
  },
});

type IHeaderModel = Pick<IFinancasRelatoriosState, 'groupBy' | 'periodo'>;

function useHeaderData() {
  const $model = useState<IHeaderModel>(s => ({
    groupBy: s.financas.relatorios.groupBy,
    periodo: s.financas.relatorios.periodo,
  }));

  const $schema = computed<IFormSchema<IHeaderModel>>(() => ({
    groupBy: {
      label: 'Agrupado por',
      type: 'select',
      items: ConstantsHelper.financasRelatoriosGroupBy,
      hideDetails: true,
      layout: {
        maxWidth: 200,
        marginBottom: 4,
      },
    },
    periodo: {
      label: 'Período',
      type: 'date-range',
      hideDetails: true,
      presets: dateRangePresetsDashboard,
      layout: {
        width: 260,
        marginBottom: 4,
      },
      max: DateHelpers.parse($model.value.periodo?.startDate)?.plus({
        months: 24,
      }),
      min: DateHelpers.parse($model.value.periodo?.endDate)?.minus({
        months: 24,
      }),
    },
  }));

  return { $model, $schema };
}

function useFilter() {
  const [$filter, setFilter] = useValue(false);

  const $filterIcon = computed(() =>
    $filter.value ? MyIcons.filterMinus : MyIcons.filterPlus,
  );

  const $filterTooltip = computed(() =>
    $filter.value ? 'Esconder filtros' : 'Mostrar filtros',
  );

  function handleFilter() {
    setFilter(!$filter.value);
  }

  return {
    $filter,
    $filterIcon,
    $filterTooltip,
    handleFilter,
  };
}
