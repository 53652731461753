import { useValue } from '@/lib/composables';
import { useMenu } from '@/lib/composables/inputs/useMenu';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { MyIcons } from '@/lib/helpers/MyIcons';
import { createComponent, watchRun } from '@/lib/vue';
import { ICalendarDateRange } from '@/typings';
import { computed, SetupContext } from '@vue/composition-api';

interface IProps {
  value: ICalendarDateRange | null;
  dayView?: boolean;
  errorMessages?: string[];
}

interface IEvents {
  onInput: (value: ICalendarDateRange | null) => void;
}

export const InputWeek = createComponent<IProps, IEvents>({
  name: 'InputWeek',
  props: {
    value: { type: Object },
    dayView: { type: Boolean, default: false },
    errorMessages: { type: Array, default: () => [] },
  },
  setup(props, ctx) {
    const id = 'InputWeek';

    const { $pos, $menu, setMenu, handleOpenMenu } = useMenu(id);

    const { $wrapperWidth, $formattedDate } = useComputeds(props);

    const { handlePickerUpdate, allowedDates, $internalValue } = useActions({
      props,
      ctx,
    });

    return () => (
      <div
        id={id}
        style={{
          maxWidth: $wrapperWidth.value,
          minWidth: $wrapperWidth.value,
        }}
        onClick={handleOpenMenu}
      >
        <v-text-field
          outlined
          readonly
          hideDetails
          label="Período"
          value={$formattedDate.value}
          errorMessages={props.errorMessages}
          appendIcon={MyIcons.date}
        />

        <InputWeekMenu
          value={$internalValue.value}
          posX={$pos.value.x}
          posY={$pos.value.y}
          show={$menu.value}
          allowedDates={allowedDates}
          onMenuChange={setMenu}
          onInput={handlePickerUpdate}
        />
      </div>
    );
  },
});

interface IPropsWeekMenu {
  value?: string | null;
  posX: number;
  posY: number;
  show: boolean;
  allowedDates: (v: string) => boolean;
}

interface IEventsWeekMenu {
  onMenuChange: (show: boolean) => any;
  onInput: (value: string | null) => void;
}

const InputWeekMenu = createComponent<IPropsWeekMenu, IEventsWeekMenu>({
  name: 'InputWeekMenu',
  props: {
    value: { type: String },
    posX: { type: Number, required: true },
    posY: { type: Number, required: true },
    show: { type: Boolean, default: false },
    allowedDates: { type: Function, required: true },
  },
  setup(props, ctx) {
    function emitMenuChange(show: boolean) {
      ctx.emit('menuChange', show);
    }

    function emitInput(value: string | null) {
      ctx.emit('input', value);

      emitMenuChange(false);
    }

    return () => (
      <v-menu
        fullWidth
        closeOnContentClick={false}
        transition="scale-transition"
        maxWidth="290px"
        minWidth="290px"
        openOnClick
        absolute
        positionX={props.posX}
        positionY={props.posY}
        value={props.show}
        onInput={emitMenuChange}
      >
        <v-date-picker
          locale="pt-br"
          value={props.value}
          noTitle
          showCurrent={false}
          allowedDates={props.allowedDates}
          onInput={emitInput}
        />
      </v-menu>
    );
  },
});

function useComputeds(props: IProps) {
  const [$noYear, setNoYear] = useValue(true);

  const $formattedDate = computed(() => {
    const value = props.value;

    if (!value) return null;

    if (props.dayView) return DateHelpers.formatDate(value.startDate);

    const { startDate, endDate } = value;

    const start = DateHelpers.parse(startDate)!;
    const end = DateHelpers.parse(endDate)!;

    setNoYear(start.year === end.year);

    return `${DateHelpers.formatDate(
      startDate,
      $noYear.value,
    )} - ${DateHelpers.formatDate(endDate, $noYear.value)}`;
  });

  const $wrapperWidth = computed(() =>
    !$noYear.value && !props.dayView ? '240px' : '150px',
  );

  return { $formattedDate, $wrapperWidth };
}

function useActions({ props, ctx }: { props: IProps; ctx: SetupContext }) {
  const [$internalValue, setInternalValue] = useValue<string | null>(null);

  function emitInput(value: ICalendarDateRange | null) {
    ctx.emit('input', value);
  }

  function allowedDates(v: string) {
    if (props.dayView || !v || !props.value) {
      return true;
    }

    const date = new Date(v);
    const start = new Date(props.value.startDate);
    const end = new Date(props.value.endDate);

    return date < start || date > end;
  }

  function handlePickerUpdate(value: string | null) {
    if (!value) return;

    setInternalValue(value);

    if (props.dayView) {
      emitInput({
        startDate: value,
        endDate: value,
      });
    } else {
      emitInput({
        startDate: DateHelpers.weekStart(value)!.toISODate(),
        endDate: DateHelpers.weekEnd(value)!.toISODate()!,
      });
    }
  }

  watchRun(
    () => props.value,
    value => {
      if (!value) return;

      setInternalValue(value.startDate);
    },
    { deep: true },
  );

  return { allowedDates, handlePickerUpdate, $internalValue };
}
