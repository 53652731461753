import { DateNavigationBtns } from '@/components/calendar/myCalendar/DateNavigationBtns';
import { useState } from '@/lib/composables';
import { FluxoCaixaService } from '@/lib/services/financas/fluxoCaixa.service';
import { createComponent } from '@/lib/vue';
import { computed } from '@vue/composition-api';
import { FluxoCaixaContasFinanceiras } from './header/FluxoCaixaContasFinanceiras';
import { FluxoCaixaType } from './header/FluxoCaixaType';
import { FluxoCaixaWeek } from './header/FluxoCaixaWeek';

export const FluxoCaixaHeader = createComponent({
  name: 'FluxoCaixaHeader',
  setup(props, ctx) {
    const { $nextTooltip, $previousTooltip } = useComputed();

    return () => (
      <div
        id="FluxoCaixaHeader"
        class="flex flex-wrap items-center p-4 space-x-4 space-y-4 sm:space-y-0"
      >
        <FluxoCaixaContasFinanceiras />

        <FluxoCaixaType />

        <v-fade-transition>
          <FluxoCaixaWeek />
        </v-fade-transition>

        <DateNavigationBtns
          class="ml-auto"
          previousTooltip={$previousTooltip.value}
          nextTooltip={$nextTooltip.value}
          onToday={FluxoCaixaService.today}
          onNext={FluxoCaixaService.next}
          onPrevious={FluxoCaixaService.previous}
        />
      </div>
    );
  },
});

function useComputed() {
  const $isMonth = useState(s => s.financas.fluxoCaixa.header.type === 'month');

  const $previousTooltip = computed(() =>
    $isMonth.value ? 'Mês anterior' : 'Semana anterior',
  );

  const $nextTooltip = computed(() =>
    $isMonth.value ? 'Próximo mês' : 'Próxima semana',
  );

  return { $isMonth, $previousTooltip, $nextTooltip };
}
