import { DataTable } from '@/components/datatable/DataTable';
import { useState } from '@/lib/composables';
import { formatDecimal } from '@/lib/form';
import { createComponent } from '@/lib/vue';
import {
  FinancasRelatoriosGroupBy,
  IDataTableHeader,
  IFinancasRelatoriosData,
} from '@/typings';
import { computed } from '@vue/composition-api';

interface IProps {}

interface IEvents {}

export const FinancasRelatoriosTable = createComponent<IProps, IEvents>({
  name: 'FinancasRelatoriosTable',
  setup(props, ctx) {
    const $state = useState(s => s.financas.relatorios);

    const $headers = useHeaders();

    return () => {
      const { loading, data } = $state.value;

      return (
        <div id="FinancasRelatoriosTable" class="my-8">
          <DataTable headers={$headers.value} items={data} loading={loading} />
        </div>
      );
    };
  },
});

function useHeaders() {
  const $groupBy = useState(s => s.financas.relatorios.groupBy);

  function mapClass({ isTotal }: IFinancasRelatoriosData) {
    return {
      'grid items-center h-12 px-4 -mx-4 font-bold bg-coolGray-100': isTotal,
    };
  }

  return computed<IDataTableHeader<IFinancasRelatoriosData>[]>(() => {
    let headers: IDataTableHeader<IFinancasRelatoriosData>[] = [
      {
        text: 'Subtotal',
        value: 'value',
        mapValue: v => v.value,
        money: true,
      },
      {
        text: 'Percentual (%)',
        value: 'percentage',
        mapValue: v => formatDecimal(v.percentage, { suffix: '%' }),
      },
    ];

    if ($groupBy.value === FinancasRelatoriosGroupBy.PROCEDIMENTO) {
      headers = [
        {
          text: 'Profissional',
          value: 'profissional',
          mapValue: v => v.profissional,
        },
        ...headers,
      ];
    }

    return [
      {
        text: getGroupByLabel($groupBy.value),
        value: 'label',
        mapValue: v => v.label,
      },
      ...headers,
    ].map(v => ({ ...v, mapClass }));
  });
}

function getGroupByLabel(groupBy: FinancasRelatoriosGroupBy | null) {
  switch (groupBy) {
    case FinancasRelatoriosGroupBy.CATEGORIA:
      return 'Categoria';

    case FinancasRelatoriosGroupBy.CENTRO_CUSTO:
      return 'Centro de custo';

    case FinancasRelatoriosGroupBy.CONTA_FINANCEIRA:
      return 'Conta financeira';

    case FinancasRelatoriosGroupBy.CONVENIO:
      return 'Convênio';

    case FinancasRelatoriosGroupBy.PACIENTE:
      return 'Paciente';

    case FinancasRelatoriosGroupBy.PROFISSIONAL:
      return 'Profissional';

    case FinancasRelatoriosGroupBy.PROCEDIMENTO:
      return 'Procedimento';

    default:
      return '';
  }
}
