import { InputSelect } from '@/components/form/inputs/InputSelect';
import { useState } from '@/lib/composables';
import { FluxoCaixaService } from '@/lib/services/financas/fluxoCaixa.service';
import { createComponent } from '@/lib/vue';

interface IProps {}

interface IEvents {}

export const FluxoCaixaContasFinanceiras = createComponent<IProps, IEvents>({
  name: 'FluxoCaixaContasFinanceiras',
  setup(props, ctx) {
    const $state = useState(s => s.financas.fluxoCaixa.contasFinanceiras);

    return () => {
      const { all, ids, loading } = $state.value;

      return (
        <div id="FluxoCaixaContasFinanceiras" class="flex max-w-xs">
          <InputSelect
            input={{
              label: 'Contas financeiras',
              type: 'select',
              items: all,
              itemLabel: 'nome',
              itemValue: 'id',
              multiple: true,
              loading,
              placeholder: 'Todas as contas',
              persistentPlaceholder: true,
              hideDetails: true,
            }}
            value={ids}
            onInput={FluxoCaixaService.setContasFinanceiras}
          />
        </div>
      );
    };
  },
});
