import { CategoriaFinanceiraGraphql } from '@/graphql/financas/categoriaFinanceira/CategoriaFinanceiraGraphql';
import { ContaFinanceiraGraphql } from '@/graphql/financas/contaFinanceira/ContaFinanceiraGraphql';
import { FinancasRelatoriosGraphql } from '@/graphql/financas/relatorios/FinancasRelatoriosGraphql';
import { DateHelpers } from '@/lib/helpers/date.helpers';
import { store } from '@/store';
import { FluxoCaixaState } from '@/store/modules/financas/fluxoCaixa';
import { IFluxoCaixaType } from '@/typings';

export const FluxoCaixaService = {
  async init() {
    await FluxoCaixaService.loadContasFinanceiras();

    Promise.all([
      FluxoCaixaService.loadCategorias(),
      FluxoCaixaService.loadData(),
    ]);
  },

  next() {
    const {
      dates: { current },
      type,
    } = store.state.financas.fluxoCaixa.header;

    const date = DateHelpers.parse(current);

    if (!date) return FluxoCaixaService.setDates(null);

    const newDate =
      type === 'month' ? date.plus({ month: 1 }) : date.plus({ week: 1 });

    return FluxoCaixaService.setDates(newDate.toISODate());
  },

  previous() {
    const {
      dates: { current },
      type,
    } = store.state.financas.fluxoCaixa.header;

    const date = DateHelpers.parse(current);

    if (!date) return FluxoCaixaService.setDates(null);

    const newDate =
      type === 'month' ? date.minus({ month: 1 }) : date.minus({ week: 1 });

    return FluxoCaixaService.setDates(newDate.toISODate());
  },

  today() {
    const today = DateHelpers.today().toISODate();

    FluxoCaixaService.setDates(today);
  },

  setDates(date: string | null) {
    FluxoCaixaState.header.setDates(date);

    FluxoCaixaService.loadData();
  },

  setTipo(tipo: IFluxoCaixaType) {
    FluxoCaixaState.header.setTipo(tipo);

    FluxoCaixaService.loadData();
  },

  setContasFinanceiras(ids: string[]) {
    FluxoCaixaState.contasFinanceiras.setIds(ids);

    FluxoCaixaService.loadData();
  },

  async loadCategorias() {
    FluxoCaixaState.categorias.setLoading(true);

    const resp = await CategoriaFinanceiraGraphql.categoriasFinanceiras({
      where: { parentId: null },
    });

    FluxoCaixaState.categorias.setData(resp);
  },

  async loadContasFinanceiras() {
    FluxoCaixaState.contasFinanceiras.setLoading(true);

    const resp = await ContaFinanceiraGraphql.contasFinanceiras({});

    FluxoCaixaState.contasFinanceiras.setAll(resp);
  },

  async loadData() {
    const {
      contasFinanceiras,
      header: { dates },
    } = store.state.financas.fluxoCaixa;

    FluxoCaixaState.report.setLoading(true);

    const data = await FinancasRelatoriosGraphql.fluxoCaixa(
      {
        dataInicial: dates.start,
        dataFinal: dates.end,
        contasFinanceirasIds: contasFinanceiras.ids.length
          ? contasFinanceiras.ids
          : contasFinanceiras.all.map(v => v.id),
      },
      true,
    );

    FluxoCaixaState.report.setAll(data);
  },
};
