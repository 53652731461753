import { MyPage } from '@/components/page/MyPage';
import { PageSection } from '@/components/page/PageSection';
import { createComponent } from '@/lib/vue';
import { TransacaoTipo } from '@/typings';
import { FinancasRelatorios } from '../../components/relatorios/FinancasRelatorios';

export default createComponent({
  name: 'FinancasRelatorioDespesasPage',
  setup(props, ctx) {
    return () => (
      <MyPage title="Análise de despesas">
        <PageSection>
          <FinancasRelatorios tipo={TransacaoTipo.DESPESA} />
        </PageSection>
      </MyPage>
    );
  },
});
