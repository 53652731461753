import { FinancasRelatoriosGraphql } from '@/graphql/financas/relatorios/FinancasRelatoriosGraphql';
import { Validate } from '@/lib/helpers/error';
import { store } from '@/store';
import { FinancasRelatoriosState } from '@/store/modules/financas/financasRelatorios.store';
import {
  FinancasRelatoriosGroupBy,
  FinancasRelatorioWhereInput,
  IFinancasRelatoriosResult,
  IFinancasRelatoriosState,
  TransacaoTipo,
} from '@/typings';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

export const FinancasRelatoriosService = {
  async init(tipo: TransacaoTipo) {
    FinancasRelatoriosState.setPeriodo();

    FinancasRelatoriosState.setWhere();

    FinancasRelatoriosState.setTipo(tipo);
  },

  async setHeader({
    groupBy,
    periodo,
  }: Pick<IFinancasRelatoriosState, 'groupBy' | 'periodo'>) {
    FinancasRelatoriosState.setGroupBy(groupBy);

    FinancasRelatoriosState.setPeriodo(periodo);
  },

  async loadData() {
    FinancasRelatoriosState.setLoading(true);

    const data = await getData();

    FinancasRelatoriosState.setData(data);
  },
};

function getWhere(): FinancasRelatorioWhereInput {
  const { where, tipo, periodo } = store.state.financas.relatorios;

  return {
    // ignore null/undefined keys on object
    ...omitBy(where, isNil),

    tipo: Validate.require(tipo, 'tipo'),
    dataInicial: Validate.require(periodo?.startDate, 'dataInicial'),
    dataFinal: Validate.require(periodo?.endDate, 'dataFinal'),
  };
}

async function getData(refetch = true): Promise<IFinancasRelatoriosResult[]> {
  const { groupBy } = store.state.financas.relatorios;

  switch (groupBy) {
    case FinancasRelatoriosGroupBy.CATEGORIA:
      return FinancasRelatoriosGraphql.transacoesPorCategoria(
        getWhere(),
        refetch,
      );

    case FinancasRelatoriosGroupBy.CENTRO_CUSTO:
      return FinancasRelatoriosGraphql.transacoesPorCentroCusto(
        getWhere(),
        refetch,
      );

    case FinancasRelatoriosGroupBy.CONTA_FINANCEIRA:
      return FinancasRelatoriosGraphql.transacoesPorContaFinanceira(
        getWhere(),
        refetch,
      );

    case FinancasRelatoriosGroupBy.CONVENIO:
      return FinancasRelatoriosGraphql.transacoesPorConvenio(
        getWhere(),
        refetch,
      );

    case FinancasRelatoriosGroupBy.PACIENTE:
      return FinancasRelatoriosGraphql.transacoesPorPaciente(
        getWhere(),
        refetch,
      );

    case FinancasRelatoriosGroupBy.PROFISSIONAL:
      return FinancasRelatoriosGraphql.transacoesPorProfissional(
        getWhere(),
        refetch,
      );

    case FinancasRelatoriosGroupBy.PROCEDIMENTO:
      return FinancasRelatoriosGraphql.transacoesPorProcedimento(
        getWhere(),
        refetch,
      ).then(r => r.map(v => ({ ...v, label: v.procedimento })));

    default:
      return [];
  }
}
