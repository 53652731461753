import { DataReportsSvg } from '@/components/illustrations/DataReportsSvg';
import { useState } from '@/lib/composables';
import { FinancasRelatoriosService } from '@/lib/services/financas/financasRelatorios.service';
import { createComponent } from '@/lib/vue';
import { TransacaoTipo } from '@/typings';
import { PropType } from 'vue';
import { FinancasRelatoriosChart } from './FinancasRelatoriosChart';
import { FinancasRelatoriosHeader } from './FinancasRelatoriosHeader';
import { FinancasRelatoriosTable } from './FinancasRelatoriosTable';

export const FinancasRelatorios = createComponent({
  name: 'FinancasRelatorios',
  props: {
    tipo: { type: String as PropType<TransacaoTipo>, required: true },
  },
  setup(props, ctx) {
    FinancasRelatoriosService.init(props.tipo);

    const $state = useState(s => s.financas.relatorios);

    return () => (
      <div id="FinancasRelatorios" class="flex flex-col">
        <FinancasRelatoriosHeader />

        <v-fade-transition mode="out-in">
          {$state.value.showTable ? (
            <div class="flex flex-col">
              <FinancasRelatoriosTable />

              <FinancasRelatoriosChart />
            </div>
          ) : (
            <div class="flex flex-col items-center my-16 space-y-8">
              <DataReportsSvg class="w-80" />
              <div class="text-coolGray-400 text-title">
                Selecione o seus filtros e clique em gerar relatório
              </div>
            </div>
          )}
        </v-fade-transition>
      </div>
    );
  },
});
