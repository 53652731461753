import { MyPage } from '@/components/page/MyPage';
import { FluxoCaixaService } from '@/lib/services/financas/fluxoCaixa.service';
import { createComponent } from '@/lib/vue';
import { FluxoCaixaHeader } from '../components/fluxoCaixa/FluxoCaixaHeader';
import { FluxoCaixaTable } from '../components/fluxoCaixa/FluxoCaixaTable';

export default createComponent({
  name: 'FluxoCaixaPage',
  setup(props, ctx) {
    FluxoCaixaService.init();

    return () => (
      <MyPage title="Fluxo de caixa">
        <FluxoCaixaHeader />

        <FluxoCaixaTable />
      </MyPage>
    );
  },
});
