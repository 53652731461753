import { createComponent } from '@/lib/vue';

export const DataReportsSvg = createComponent({
  name: 'DataReportsSvg',
  setup(props, ctx) {
    return () => (
      <div id="DataReportsSvg">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-full"
          viewBox="0 0 823.5 539.23257"
        >
          <ellipse cx="118" cy="530.23257" fill="#e6e6e6" rx="118" ry="9" />
          <path
            fill="#9f616a"
            d="M156.218 135.46082l-47.07583-12.83886q18.85578-17.793 16.40521-33.5237H156.218z"
          />
          <path
            fill="#2f2e41"
            d="M161.211 496.376l-15.206 2.139-18.318-168.331-29.244 167.618-15.692.713V285.961h97.005l-18.545 210.415z"
          />
          <path
            fill="#2f2e41"
            d="M90.64831 530.30936a10.69525 10.69525 0 0010.60707-7.816l1.11072-16.57479-2.23906-11.75506c-5.90752-4.85489-11.87976-4.75376-17.91246 0L80.291 518.78545a10.69524 10.69524 0 0010.35731 11.52391zM153.41608 530.30936a10.69525 10.69525 0 0010.60707-7.816l1.11072-16.57479-2.23905-11.75506c-5.90753-4.85489-11.87976-4.75376-17.91247 0l-1.92359 24.62195a10.69525 10.69525 0 0010.35732 11.5239z"
          />
          <circle cx="149.79858" cy="84.10538" r="27.10427" fill="#9f616a" />
          <path
            fill="#e6e6e6"
            d="M184.03555 289.52717H80.61137c13.40488-62.12418 13.36732-112.15823 2.68969-152.62266a10.81293 10.81293 0 016.57562-12.85324l27.82474-10.70182c8.35359 10.788 20.1417 15.5 38.51659 8.55924l14.58078 2.99093a14.98248 14.98248 0 0111.957 15.66542c-1.07315 15.82559-.65 31.77226-1.57328 45.538-2.85916 42.62824-4.09396 75.86033 2.85304 103.42413z"
          />
          <path
            fill="#2f2e41"
            d="M135.53318 48.44187s15.69194-17.83176 34.237-.71327l4.99289 1.42654-3.56635 2.85308s18.545 8.55924 17.11849 26.391c0 0-2.85308-.71327-4.27962 0s-2.13982 8.55924-2.13982 8.55924-2.13981-5.70616-2.85308-6.41943-10.699 7.846-27.10426-4.99289c0 0-4.99289 12.83886-10.699 11.41232s-13.55214-.71327-17.11849 8.55925c-.00009-.00001-19.25838-41.36968 11.41224-47.07584z"
          />
          <path
            fill="#9f616a"
            d="M189.02844 273.83528l-9.15617 12.272a12.02693 12.02693 0 002.59424 16.93944 12.02693 12.02693 0 0018.92117-7.8472l2.61941-16.37132L209 208.21438l-3.56635-35.66351-22.82465 8.55924 6.41944 30.67062z"
          />
          <path
            fill="#e6e6e6"
            d="M179.75592 189.66935l27.81754-12.83886-1.42654-10.10582a41.48828 41.48828 0 00-26.4891-38.68205l-8.46114-3.28085z"
          />
          <path
            fill="#9f616a"
            d="M78.47156 270.98215l9.15617 12.272a12.02693 12.02693 0 01-2.59424 16.93944 12.02693 12.02693 0 01-18.92117-7.8472l-2.61941-16.37132L58.5 205.36128l3.56635-35.66351 22.82465 8.55924-6.41944 30.67062z"
          />
          <path
            fill="#e6e6e6"
            d="M87.74408 186.81628l-27.81754-12.83886 1.42654-10.10582a41.48828 41.48828 0 0126.4891-38.68205l8.46114-3.28085zM816 459.73256H340a7.5082 7.5082 0 01-7.5-7.5v-207a7.5082 7.5082 0 017.5-7.5h476a7.5082 7.5082 0 017.5 7.5v207a7.5082 7.5082 0 01-7.5 7.5zm-476-219a4.50492 4.50492 0 00-4.5 4.5v207a4.50491 4.50491 0 004.5 4.5h476a4.50491 4.50491 0 004.5-4.5v-207a4.50492 4.50492 0 00-4.5-4.5z"
          />
          <path
            fill="#e6e6e6"
            d="M359.5 263.23256a4.5 4.5 0 000 9h63a4.5 4.5 0 000-9zM790.79035 294.52771v83.44957H365.95958v-43.93766c2.94182-1.88912 5.90143-3.76937 8.88768-5.58753 12.75379-7.80481 26.40529-15.10408 41.28325-17.80921a62.31953 62.31953 0 0121.31262-.26606c7.46567 1.23282 14.61134 3.80489 21.68594 6.38578 6.9768 2.55431 13.9714 5.18841 21.2682 6.66959a62.2716 62.2716 0 0021.5704.4257c13.66035-2.04876 26.49415-7.53875 39.27457-12.50543 12.86049-4.99332 26.112-9.7117 40.03011-10.33255 13.74031-.62085 27.31181 2.62528 40.35 6.65184 26.58306 8.20394 53.726 20.37239 81.87329 12.02652 28.58273-8.46112 54.87248-32.8867 86.54811-25.64059a1.19244 1.19244 0 01.7466.47003z"
          />
          <path
            fill="#0e7490"
            d="M790.79035 295.98228a1.28259 1.28259 0 01-1.45455.63855 47.36842 47.36842 0 00-11.5121-1.18843c-31.15722.54988-55.11271 25.63172-85.57811 29.38339-27.46769 3.37913-53.32114-9.97776-79.148-16.95775-13.419-3.62749-27.25483-5.55207-41.06406-3.08649-13.70274 2.439-26.563 8.0443-39.4764 13.01992-12.43452 4.78929-25.35683 9.2416-38.82014 9.46333-14.80256.24831-28.13287-5.85365-41.86225-10.50106-7.13963-2.42125-14.46553-4.337-22.03086-4.52323a66.64492 66.64492 0 00-21.71164 3.36138c-14.58971 4.63858-27.7337 12.90455-40.53188 21.11737a1.13679 1.13679 0 01-.75385.204 1.37378 1.37378 0 01-.58534-2.50109l.58534-.37255c2.93567-1.88912 5.8891-3.76937 8.86911-5.58753 12.72717-7.80481 26.35017-15.10408 41.19707-17.80921a62.06159 62.06159 0 0121.26813-.26606c7.45008 1.23282 14.58083 3.80489 21.64067 6.38578 6.96223 2.55431 13.94222 5.18841 21.22379 6.66959a62.014 62.014 0 0021.52537.4257c13.63183-2.04876 26.43884-7.53875 39.19258-12.50543 12.83364-4.99332 26.05747-9.7117 39.94654-10.33255 13.71162-.62085 27.25479 2.62528 40.26577 6.65184 26.52756 8.20394 53.61383 20.37239 81.70236 12.02652 28.52306-8.46112 54.75793-32.8867 86.36743-25.64059a1.1897 1.1897 0 01.745.47 1.36658 1.36658 0 01.00002 1.4546z"
          />
          <circle cx="424.49576" cy="311.45889" r="6.20838" fill="#0e7490" />
          <circle cx="581.47916" cy="303.47668" r="6.20838" fill="#0e7490" />
          <circle cx="773.05211" cy="295.49447" r="6.20838" fill="#0e7490" />
          <path
            fill="#e6e6e6"
            d="M421 413.23256a4.5 4.5 0 000 9h63a4.5 4.5 0 000-9z"
          />
          <circle cx="380.5" cy="417.23257" r="17" fill="#0e7490" />
          <path
            fill="#e6e6e6"
            d="M573 413.23256a4.5 4.5 0 000 9h63a4.5 4.5 0 000-9z"
          />
          <circle cx="532.5" cy="417.23257" r="17" fill="#0e7490" />
          <path
            fill="#e6e6e6"
            d="M725 413.23256a4.5 4.5 0 000 9h63a4.5 4.5 0 000-9z"
          />
          <circle cx="684.5" cy="417.23257" r="17" fill="#0e7490" />
          <path
            fill="#e6e6e6"
            d="M783.419 181.84198H548.581c-4.578 0-8.288-2.643-8.293-5.9v-51.042c.005-3.257 3.715-5.9 8.293-5.9h234.838c4.578 0 8.288 2.643 8.293 5.9v51.041c-.004 3.25701-3.715 5.897-8.293 5.901zm-234.838-60.484c-2.747 0-4.973 1.586-4.976 3.54v51.041c0 1.954 2.229 3.538 4.976 3.54h234.838c2.747 0 4.973-1.586 4.976-3.54v-51.039c0-1.954-2.229-3.538-4.976-3.54z"
            data-name="Path 78"
          />
          <path
            fill="#0e7490"
            d="M601.67905 148.00715a5.00589 5.00589 0 00-5 5v35a5.00589 5.00589 0 005 5h179a5.00589 5.00589 0 005-5v-35a5.00588 5.00588 0 00-5-5z"
          />
          <circle cx="626.67905" cy="171.00715" r="11" fill="#fff" />
          <path
            fill="#fff"
            d="M676.81094 166.23256a4.5 4.5 0 000 9h63a4.5 4.5 0 000-9z"
          />
          <path
            fill="#e6e6e6"
            d="M607.419 62.84198H372.581c-4.578 0-8.288-2.643-8.293-5.9v-51.042c.005-3.257 3.715-5.9 8.293-5.9h234.838c4.578 0 8.288 2.643 8.293 5.9v51.041c-.004 3.25701-3.715 5.897-8.293 5.901zm-234.838-60.484c-2.747 0-4.973 1.586-4.976 3.54v51.041c0 1.954 2.229 3.538 4.976 3.54h234.838c2.747 0 4.973-1.586 4.976-3.54v-51.039c0-1.954-2.229-3.538-4.976-3.54z"
            data-name="Path 78"
          />
          <path
            fill="#0e7490"
            d="M425.67905 29.00715a5.00589 5.00589 0 00-5 5v35a5.00589 5.00589 0 005 5h179a5.00589 5.00589 0 005-5v-35a5.00588 5.00588 0 00-5-5z"
          />
          <circle cx="450.67905" cy="52.00715" r="11" fill="#fff" />
          <path
            fill="#fff"
            d="M500.81094 47.23256a4.5 4.5 0 000 9h63a4.5 4.5 0 000-9z"
          />
        </svg>
      </div>
    );
  },
});
