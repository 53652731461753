import { InputSelect } from '@/components/form/inputs/InputSelect';
import { useState } from '@/lib/composables';
import { ConstantsHelper } from '@/lib/constants/helper';
import { FluxoCaixaService } from '@/lib/services/financas/fluxoCaixa.service';
import { createComponent } from 'vue-tsx-support';

export const FluxoCaixaType = createComponent({
  name: 'FluxoCaixaType',
  setup(props, ctx) {
    const $tipo = useState(s => s.financas.fluxoCaixa.header.type);

    return () => (
      <div id="FluxoCaixaType" style={{ maxWidth: '130px' }}>
        <InputSelect
          input={{
            label: 'Tipo',
            type: 'select',
            items: ConstantsHelper.fluxoCaixaTypes,
            hideDetails: true,
          }}
          value={$tipo.value}
          onInput={FluxoCaixaService.setTipo}
        />
      </div>
    );
  },
});
