import { InputWeek } from '@/components/form/inputs/InputWeek';
import { useState } from '@/lib/composables';
import { FluxoCaixaService } from '@/lib/services/financas/fluxoCaixa.service';
import { createComponent } from '@/lib/vue';
import { ICalendarDateRange } from '@/typings';

interface IProps {}

interface IEvents {}

export const FluxoCaixaWeek = createComponent<IProps, IEvents>({
  name: 'FluxoCaixaWeek',
  setup(props, ctx) {
    const $state = useState(s => s.financas.fluxoCaixa.header);

    function handleInput(value: ICalendarDateRange | null) {
      if (!value) return FluxoCaixaService.setDates(null);

      FluxoCaixaService.setDates(value.startDate);
    }

    return () => {
      const { type, dates } = $state.value;

      if (type === 'month') return null;

      return (
        <InputWeek
          value={{
            startDate: dates.start,
            endDate: dates.end,
          }}
          class="mr-4"
          onInput={handleInput}
        />
      );
    };
  },
});
